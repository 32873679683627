import { useState, useEffect } from "react";
import styled from "styled-components";
import TextField from "../../components/forms/TextField";
import Navbar from "../../components/Nav4Landing";
import StatusMessage from "../../components/message/StatusMessage";
import { useRouter } from "next/router";

import {
  reqOptions,
  fetchAPI,
  HOST_URL,
  setCookie,
  spinBtn,
} from "../../assets/js/help_func";
import Link from "next/link";
import PasswordField from "../../components/forms/PasswordField";
import SeoHeader from "../../components/navbar/SeoHeader";
import SubmitBtn from "../../components/forms/SubmitBtn";
import NewSubmitBtn from "../../components/forms/NewSubmitBtn";
import FormError from "../../components/forms/FormError";

const Login = () => {
  const router = useRouter();
  const searchParams = new URLSearchParams(router.query);
  const [data, setData] = useState([]);

  /* 
    Show message for "success" or "error".
    The default state is ''.
    Render the StatusMessage below the Header
    props: [type, setType, message]
  */
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    if (data && data.access_token) {
      setCookie("access", data.access_token, 30);
      setCookie("refresh", data.refresh_token, 30);
      setCookie("u_id", data.user.id, 30);
      setCookie("email", data.user.email, 30);
      setCookie("last_login", data.user.last_login, 30);
      setCookie("is_staff", data.user.is_staff, 30);
      setCookie("verified", data.user.verified, 30);
      if (data.company && data.company[0]) {
        setCookie("c_id", data.company[0].company.id, 30);
        setCookie("c_name", data.company[0].company.name, 30);
        setCookie(
          "c_username",
          data.company[0].company.username.toLowerCase(),
          30
        );
        setCookie("companies", JSON.stringify(data.company), 30);
        setCookie("c_logo", JSON.stringify(data.logo), 30);
      }
      if (searchParams.get("next")) router.push(searchParams.get("next"));
      else
        router.push(
          `/${data.company[0].company.username.toLowerCase()}/leads/`
        );
    }
  }, [data, message, status]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let requestOptions = reqOptions("post", new FormData(e.target));
    console.log("HOST_URL: " + HOST_URL);

    fetchAPI(
      setData,
      HOST_URL + "/api/v1/users/auth/login/",
      requestOptions,
      true,
      setStatus,
      setError
    );
  };

  return (
    <>
      <SeoHeader title="Login" />
      <section className="formfd">
        <Navbar />
        <StatusMessage
          status={status}
          setStatus={setStatus}
          message={error && error.company_username && error.company_username[0]}
        />

        <Container>
          <LoginContainer onSubmit={handleSubmit} id="loginForm">
            <h1>Welcome Back</h1>

            <TextField types="text" labels="Email Address" names="email" />
            <PasswordField labels="Password" names="password" />

            <div className="reset-password">
              <Link href="/accounts/password_reset">Forget Password ?</Link>
            </div>

            {/* {message && <p className={status &&('err_p_tag')}>{message}</p>} */}

            <FormError errors={error} status={status} />
            <NewSubmitBtn labels="Login" status={status} />

            <div className="text_agreement">
              <p>
                New to InstinctHub? <Link href="/accounts/join">Sign up</Link>{" "}
              </p>

              <p className="acceptance">
                I accept InstinctHub <Link href="">Terms of Use</Link> and
                Privacy Notice. Having trouble logging in?{" "}
                <Link href=""> Contact Center</Link>
              </p>
            </div>
          </LoginContainer>
        </Container>
      </section>
    </>
  );
};

export default Login;
let Container = styled.section`
  padding: 0 20px;
  .text_agreement {
    margin-top: 20px;
    p {
      text-align: center;
      margin-top: 20px;
    }
    a {
      color: rgba(0, 131, 143, 1);
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
const LoginContainer = styled.form`
  padding: 40px;
  background: #ffffff;
  border: 1px solid rgba(44, 51, 58, 0.2);
  border-radius: 5px;
  margin: 100px auto;
  margin-bottom: 0;
  @media (max-width: 540px) {
    padding: 40px 20px;
  }
  .new_here {
    p {
      text-align: center;
      margin-bottom: 30px;
      a {
        color: rgba(0, 131, 143, 1);
      }
    }
  }
  button {
    width: 100%;
  }
  h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 70px;
  }
  max-width: 470px;
  .reset-password {
    margin: 10px 0;
    text-align: right;
    a {
      text-align: right;
    }
  }

  .or_line {
    text-align: center;
    p {
      text-align: center;
      padding: 10px;
      &:before {
        content: "";
        display: inline-block;
        width: 48%;
        width: 100px;
        top: -4px;
        height: 1px;
        background: rgba(51, 51, 51, 0.15);
        position: relative;
        left: -10px;
      }
      &:after {
        content: "";
        display: inline-block;
        top: -4px;
        width: 48%;
        width: 100px;
        height: 1px;
        right: -10px;
        position: relative;
        background: rgba(51, 51, 51, 0.15);
      }
    }
  }
`;
